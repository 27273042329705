<template>
    <card-list-item @click="$emit('click')" :class="{'active': active}">
        <template v-slot:icon><i class="fa-fw fa-hotel" :class="{'fas': active, 'far': !active}"></i></template>
        <template v-slot:content>
            <div class="tile-title">{{hotel.title}}</div>
            <div class="meta"><i class="far fa-clock fa-fw mr-2"></i>{{hotel.start_at | moment('timezone', hotel.start_tz, 'MMM, Do, h:mm A')}} - {{hotel.end_at | moment('timezone', hotel.end_tz, 'MMM, Do, h:mm A')}}</div>
            <div class="meta"><i class="far fa-bed fa-fw mr-2"></i>{{hotel.room_type_title}}</div>
            <div class="meta" v-if="hotel.second_id"><i class="far fa-user-friends fa-fw mr-2"></i>{{hotel.second_last_name}}, {{hotel.second_first_name}}</div>
        </template>
        <template v-slot:status>

        </template>
        <template v-slot:details>
            <div class="details-rollout">
                <div class="data mt-3">
                    <data-row mini title="Room #">{{hotel.room_number}}</data-row>
                    <data-row mini title="Beds">{{hotel.bed_count}}</data-row>
                    <data-row mini title="Timezone">{{hotel.start_tz}}</data-row>
                    <data-row mini title="Created">{{hotel.created_at | moment('MM/DD/YYYY')}}</data-row>
                    <data-row mini title="ID">{{hotel.id}}</data-row>
                </div>
            </div>
        </template>
    </card-list-item>
</template>

<script>
    import Toolbar from "../TIER/components/Toolbar";
    import DataRow from "../TIER/components/DataRow";
    import CardListItem from "../TIER/components/CardListItem";

    export default {
        name: 'RegHotelListItem',
        components: {CardListItem, DataRow, Toolbar},
        props: {
            card: {
                required: true
            },
            hotel: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                details: false
            }
        }
    }
</script>

<style lang="scss" scoped>

    li {
        color: darken($t-primary, 10);

        .tile-title {
            font-size: .9rem;
            font-weight: 700;
        }

        padding: 0.5rem 0;

        .details-rollout {
            margin-top: 0.5rem;
        }
    }

    .drag-handle {
        cursor: ns-resize;
    }
</style>
