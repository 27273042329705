<template>
    <div>
        <card-header title="Hotel Information" icon="fa-hotel"/>

        <card-toolbar>
            <button @click="$openCard('new-reg-hotel', {registrationId: props.registrationId}, card)"><i class="fas fa-plus mr-2"/>New Hotel</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <reg-hotel-list-item
                        :card="card"
                        :hotel="hotel"
                        v-for="hotel in hotels"
                        :active="child && child.definition.component === 'edit-reg-hotel' && child.props.hotelId === hotel.id.toString()"
                        @click="$openCard('edit-reg-hotel', {registrationId: props.registrationId, hotelId: hotel.id}, card)"
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import {client as http} from '../../http_client';

    import CardHeader from '../../TIER/components/CardHeader';
    import CardToolbar from '../../TIER/components/CardToolbar';
    import CardBody from '../../TIER/components/CardBody';
    import CardList from '../../TIER/components/CardList';
    import FlightListItem from '../../components/FlightListItem';
    import RegHotelListItem from "../../components/RegHotelListItem";

    export default {
        props: ['card', 'child', 'props'],
        components: {RegHotelListItem, FlightListItem, CardBody, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                hotels: []
            }
        },
        methods: {
            loadHotels: function(force) {
                this.$emit('loading', true);
                http.get('/api/registrations/' + this.props.registrationId + '/hotels', {force}).then(response => {
                    this.hotels = response.data;
                    this.$emit('loading', false);
                });
            },
            reload: function() {
                this.loadHotels(true);
            }
        },
        mounted() {
            this.loadHotels();
        }
    };
</script>
